import {
  GridOptions,
  GridApi,
  ColDef,
  ColGroupDef,
  createGrid,
} from '@ag-grid-community/core';

import { JSX, createEffect, createSignal, onCleanup, onMount } from 'solid-js';

interface AgGridProps<T> {
  ref?: (api: GridApi) => void;

  className?: string;
  columnDefs: (ColDef | ColGroupDef)[];
  data: T[];
  autosizeColumns?: boolean;
  loading?: boolean;
  noRows?: boolean;

  gridOptionsRef?: (opts: GridOptions) => void;

  getRowId?: GridOptions['getRowId'];
  onGridReady?: any;
  onFilterChanged?: any;
  onSortChanged?: any;
  onRowClicked?: any;
  onRowDoubleClicked?: any;
  onDataLoaded?: (rows: T[]) => void;
}

export function AgGrid<T>(props: AgGridProps<T>): JSX.Element {
  const [gridIsReady, setGridIsReady] = createSignal(false);

  let grid_ref: HTMLDivElement | undefined;

  let grid: GridApi;
  let gridOptions: GridOptions;

  onMount(() => {
    gridOptions = {
      columnDefs: props.columnDefs,
      onGridReady: () => {
        setGridIsReady(true);
        props.onGridReady?.();
      },
      // TODO: How to allow copy&pasting well?
      //enableCellTextSelection: true,
      //ensureDomOrder: true,
      animateRows: false,

      getRowId: props.getRowId,
      onFilterChanged: props.onFilterChanged,
      onSortChanged: props.onSortChanged,
      onRowClicked: props.onRowClicked,
      onRowDoubleClicked: props.onRowDoubleClicked,
    };
    grid = createGrid(grid_ref!, gridOptions);
    props.ref?.(grid);
    props.gridOptionsRef?.(gridOptions);
  });

  onCleanup(() => {
    grid.destroy();
  });

  createEffect(() => {
    if (gridIsReady()) {
      grid.setGridOption('rowData', props.data);

      if (props.autosizeColumns) {
        grid.autoSizeAllColumns();
      }

      props.onDataLoaded?.(props.data);
    }
  });

  createEffect(() => {
    if (props.loading) {
      grid.showLoadingOverlay();
    } else {
      grid.hideOverlay();
    }
  });
  createEffect(() => {
    if (props.noRows) {
      grid.showNoRowsOverlay();
    } else {
      grid.hideOverlay();
    }
  });

  return (
    <div
      class={(props.className ?? '') + ' ag-theme-balham-dark'}
      ref={grid_ref}
    />
  );
}
