import { createResource, createSignal, Component, For, Show } from 'solid-js';
import { getStatus, encrypt, exec } from './api';
import { Status } from './types';
import styles from './Admin.module.scss';
import { useParams, useNavigate } from '@solidjs/router';

const kServer =
  '4b4b8a51e4778a0726de2f75c642dad03e07068b025c1f63372fc6ad0305581b9fdd8f982bdff6921eb36a1a38d47ddf040813fa55e05dddf020';

async function slay(address: string, userid: number) {
  exec(address, `sm_slay #${userid}`);
}

const Details: Component<{ address: string; status: Status }> = (props) => {
  return (
    <>
      <h2>Server: {props.status.hostname}</h2>
      <h2>
        Address: {props.status.address} (
        <a href={`steam://connect/${props.status.address}`}>Join</a>)
      </h2>

      <h2>Death Match Mode</h2>
      <ul>
        <li>
          <button onclick={() => exec(props.address, 'sm_dm on')}>DM on</button>
        </li>
        <li>
          <button onclick={() => exec(props.address, 'sm_dm off')}>
            DM off
          </button>
        </li>
        <li>
          <button onclick={() => exec(props.address, 'sm_votedm')}>
            Vote to toggle DM
          </button>
        </li>
      </ul>

      <h2>General Admin</h2>
      <ul>
        <li>
          <button onclick={() => exec(props.address, 'sm_map cp_powerhouse')}>
            Restart Map
          </button>
        </li>
      </ul>

      <h2>Players</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Duration</th>
            <th>Ping</th>
            <th>Packet Loss</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <For each={props.status.players}>
            {(p) => (
              <tr>
                <td>
                  <a href={`https://steamcommunity.com/profiles/${p.id64}`}>
                    {p.name}
                  </a>
                </td>
                <td>{p.name}</td>
                <td>{p.ping}</td>
                <td>{p.loss}</td>
                <td>
                  <button onclick={() => slay(props.address, p.id)}>
                    Slay
                  </button>
                  <button>Kick</button>
                </td>
              </tr>
            )}
          </For>
        </tbody>
      </table>
    </>
  );
};

export const AdminServer: Component<{ address: string }> = (props) => {
  const [status, { mutate, refetch }] = createResource(
    props.address,
    getStatus,
  );

  return (
    <div class={styles.admin}>
      <h1>Server Admin Panel</h1>
      {status.loading || !status()
        ? 'Loading...'
        : status.error ?? (
            <Details address={props.address} status={status()!} />
          )}
    </div>
  );
};

export const AdminNoServer: Component<{}> = (props) => {
  const [address, setAddress] = createSignal('');
  const [password, setPassword] = createSignal('');
  const navigate = useNavigate();

  return (
    <div class={styles.admin}>
      <h1>Server Admin Panel</h1>
      <label>
        Server Address (ip:port):{' '}
        <input
          type='text'
          name='address'
          value={address()}
          oninput={(e: any) => setAddress(e.target.value)}
        />
      </label>
      <br />
      <label>
        RCON Password:{' '}
        <input
          type='password'
          name='password'
          value={password()}
          oninput={(e: any) => setPassword(e.target.value)}
        />
      </label>
      <br />
      <button
        onclick={async () => {
          const token = await encrypt(address(), password());
          navigate(`/admin/${token}`);
        }}
      >
        Connect
      </button>
    </div>
  );
};

export const Admin: Component<{}> = (props) => {
  const params = useParams();
  const [status, { mutate, refetch }] = createResource(
    params.address,
    getStatus,
  );

  return (
    <Show when={params.address} fallback={<AdminNoServer />}>
      <AdminServer address={params.address} />
    </Show>
  );
};
