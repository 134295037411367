import { Server, Player, ServerRule, Status } from './types';
import { cleanServer } from './utils';

// Should end in a /
const base = import.meta.env.VITE_API_BASE;

export async function getServers(region: string): Promise<Server[]> {
  let res = await fetch(`${base}servers?region=${region}`);
  let msg = await res.json();
  return msg.map(cleanServer);
}

export async function getPlayers(address: string): Promise<Player[]> {
  let res = await fetch(`${base}server/${address}/players`);
  let msg = await res.json();
  return msg;
}

export async function getRules(address: string): Promise<ServerRule[]> {
  let res = await fetch(`${base}server/${address}/rules`);
  let msg = await res.json();
  return msg;
}

export async function getStatus(address: string): Promise<Status> {
  let res = await fetch(`${base}rcon/${address}/status`);
  let msg = await res.json();
  return msg;
}

export async function exec(address: string, command: string): Promise<string> {
  let res = await fetch(`${base}rcon/${address}/exec`, {
    method: 'POST',
    body: command,
  });
  let msg = await res.text();
  return msg;
}

export async function encrypt(
  address: string,
  password: string,
): Promise<string> {
  let res = await fetch(`${base}rcon/${address}/${password}/encrypt`);
  let msg = await res.text();
  return msg;
}
