import {
  onMount,
  onCleanup,
  Show,
  For,
  createResource,
  useContext,
  JSXElement,
} from 'solid-js';
import { Grid, GridOptions, GridApi } from '@ag-grid-community/core';
import { StoreContext } from './store';
import { StreamContext } from './stream';
import { Player, Server, ServerRule, kRegionName } from './types';
import { getPlayers, getRules } from './api';
import { Tabs } from './Tabs';
import { AgGrid } from './AgGrid';
import styles from './ServerView.module.scss';

function secondsDisplay(all_seconds: number) {
  const hours = Math.floor(all_seconds / 3600);
  const minutes = Math.floor((all_seconds % 3600) / 60);
  const seconds = Math.floor(all_seconds % 60);
  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
}

const caseInsensitiveComparator = (a: string, b: string) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
};

const kPlayersGridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    comparator: caseInsensitiveComparator,
  },
  { headerName: 'Score', field: 'score', sortable: true },
  {
    headerName: 'Connected Time',
    field: 'seconds',
    sortable: true,
    valueFormatter: ({ data: { seconds } }: any) => secondsDisplay(seconds),
  },
];

function PlayersTable(props: {
  players: Player[] | undefined;
  loading: boolean;
  error: any;
}): JSXElement {
  return (
    <>
      {props.error}
      <AgGrid
        className={styles.grid}
        columnDefs={kPlayersGridColumns}
        data={props.error ? [] : props.loading ? [] : props.players || []}
        loading={props.loading}
        autosizeColumns={true}
      />
    </>
  );
}

const kRulesGridColumns = [
  { headerName: 'Rule', field: '0', sortable: true },
  { headerName: 'Value', field: '1', sortable: true, resizable: true },
];

function RulesTable(props: {
  rules: ServerRule[] | undefined;
  loading: boolean;
  error: any;
}): JSXElement {
  return (
    <Show when={props.rules}>
      {props.error ? (
        'Error fetching rules'
      ) : (
        <AgGrid
          className={styles.grid}
          columnDefs={kRulesGridColumns}
          data={props.error ? [] : props.loading ? [] : props.rules || []}
          loading={props.loading}
          autosizeColumns={true}
        />
      )}
    </Show>
  );
}

export function ServerView(props: { address: string }): JSXElement {
  const store = useContext(StoreContext)!;
  const stream = useContext(StreamContext)!;
  const server = () => {
    return store.servers[props.address];
  };

  const [players, { refetch: refetchPlayers }] = createResource(
    () => props.address,
    getPlayers,
    { initialValue: [] },
  );
  const [rules, { refetch: refetchRules }] = createResource(
    () => props.address,
    getRules,
  );

  let updates_sub: any;
  onMount(() => {
    updates_sub = stream.subscribe((s) => {
      if (s.address == props.address) {
        refetchPlayers();
        refetchRules();
      }
    });
  });
  onCleanup(() => updates_sub.unsubscribe());

  return (
    <div class={styles.server_view}>
      <h1>{server().name}</h1>
      <table class={styles.details}>
        <tbody>
          <tr>
            <td>Players:</td>
            <td>
              {server().players} / {server().max_players} ({server().bots} bots)
            </td>
          </tr>
          <tr>
            <td>Address:</td>
            <td>{server().address}</td>
          </tr>
          <tr>
            <td>Game:</td>
            <td>{server().game}</td>
          </tr>
          <tr>
            <td>Region:</td>
            <td>{kRegionName.get(server().region)}</td>
          </tr>
          <tr>
            <td>Keywords:</td>
            <td>{server().keywords.join(', ')}</td>
          </tr>
        </tbody>
      </table>
      <a
        class={styles.connect_button}
        href={`steam://connect/${server().address}`}
        target='_self'
      >
        Connect
      </a>
      <Tabs
        tabs={[
          [
            'Players',
            () => (
              <PlayersTable
                error={players.error}
                loading={players.loading}
                players={players()}
              />
            ),
          ],
          [
            'Rules',
            () => (
              <RulesTable
                error={rules.error}
                loading={rules.loading}
                rules={rules()}
              />
            ),
          ],
        ]}
        nested_props={{}}
      />
    </div>
  );
}
