import { Server, Player, ServerRule, Status } from './types';

export function sample<T>(a: T[]): T | undefined {
  return a[Math.floor(Math.random() * a.length)];
}

export function cleanServer(server: Server) {
  return {
    ...server,
    name: server.name.replaceAll(/^[]*|[█]*$/g, ''),
  };
}
