/* @refresh reload */
import { render } from 'solid-js/web';
import './index.scss';
import { App, Internet, Favorites } from './App';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-balham.css';
import { Router, Route } from '@solidjs/router';
import { Admin } from './Admin';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

render(
  () => (
    <Router>
      <Route path='*' component={App}>
        <Route path='/' component={Internet} />
        <Route path='/favorites' component={Favorites} />
        <Route path='/admin' component={Admin} />
        <Route path='/admin/:address' component={Admin} />
        <Route path='*404' component={() => <h1>404</h1>} />
      </Route>
    </Router>
  ),
  document.getElementById('root') as HTMLElement,
);
