import { GridOptions, GridApi } from '@ag-grid-community/core';
import { bufferTime } from 'rxjs/operators';
import { always, tryCatch } from 'ramda';
import {
  Accessor,
  createSignal,
  Component,
  createEffect,
  onCleanup,
  onMount,
} from 'solid-js';
import {
  IconHeader,
  PlayersFilter,
  RegionFilter,
  JoinFilter,
} from './AgGridComponents';
import { kRegionName, kRegionAbbreviation, Server, Region } from './types';
import { Observable, Subscription } from 'rxjs';
import styles from './ServersGrid.module.scss';
import lock_url from './assets/lock.svg';
import shield_url from './assets/shield.svg';
import { AgGrid } from './AgGrid';

type GridContext = { data: Server };

const lock = (() => {
  const img = document.createElement('img');
  img.src = lock_url;
  img.width = 12;
  img.className = styles.icon;
  img.title = 'Password Protected';
  return () => img.cloneNode();
})();

const shield = (() => {
  const img = document.createElement('img');
  img.src = shield_url;
  img.width = 12;
  img.className = styles.icon;
  img.title = 'Valve Anti-Cheat Enabled';
  return () => img.cloneNode();
})();

const empty = (() => {
  const span = document.createElement('span');
  return () => span.cloneNode();
})();

function getRowId(s: Server): string {
  return s.address;
}

// Hacky: api.refreshHeader() does all sorts of weird shit, so do this:
function header(api: GridApi) {
  let total = 0;
  api.forEachNode(() => total++);

  let displayed = 0;
  api.forEachNodeAfterFilter(() => displayed++);
  return `Servers ${displayed} (of ${total} total)`;
}

function updateHeader(api: GridApi) {
  let header_el: HTMLElement | null = document.querySelector(
    '.ag-header-cell[col-id="name"] .ag-header-cell-text',
  );
  if (header_el) {
    header_el.innerText = header(api);
  }
}

const kServerColumnDefs = [
  {
    headerName: 'Password',
    headerComponent: IconHeader,
    headerComponentParams: {
      icon: lock(),
    },
    field: 'password',
    sortable: true,
    resizable: false,
    width: 20,
    cellRenderer: (params: any) => {
      return params.value ? lock() : empty();
    },
  },
  {
    headerName: 'VAC',
    headerComponent: IconHeader,
    headerComponentParams: {
      icon: shield(),
    },
    field: 'vac',
    sortable: true,
    resizable: false,
    width: 20,
    cellRenderer(params: any) {
      return params.value ? shield() : empty();
    },
  },
  {
    headerName: 'Servers',
    field: 'name',
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 10,
    },
    sortable: true,
    width: 360,
    resizable: true,
  },
  {
    headerName: 'Players',
    field: 'players',
    filter: PlayersFilter,
    sortable: true,
    width: 80,
    resizable: false,
    valueGetter: (params: GridContext) =>
      params.data.players - params.data.bots,
    cellRenderer: (params: GridContext) => {
      return `${params.data.players - params.data.bots}/${
        params.data.max_players
      }`;
    },
  },
  {
    headerName: 'Map',
    field: 'map',
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 10,
    },
    sortable: true,
    width: 150,
    resizable: true,
  },
  {
    headerName: 'Region',
    field: 'region',
    filter: RegionFilter,
    sortable: true,
    width: 80,
    resizable: false,
    cellRenderer: (params: any) => {
      return kRegionAbbreviation.get(params.value) ?? '';
    },
  },
  {
    headerName: 'Connect',
    filter: JoinFilter,
    sortable: false,
    width: 90,
    resizable: false,
    valueGetter: (params: GridContext) => {
      return (params.data.players << 10) | params.data.max_players;
    },
    cellRenderer: (params: GridContext) => {
      if (params.data.players >= params.data.max_players) {
        return 'FULL';
      } else {
        return `<a class="${styles.join_button}" href="steam://connect/${params.data.address}" target="_self">Join</a>`;
      }
    },
  },
];

function serializeSortAndFilter(api: GridApi) {
  const filters = api.getFilterModel();
  const columns = api.getColumnState();

  const filterString = JSON.stringify(filters);

  const sortString = columns
    .filter((c) => c.sort)
    .map((c) => `${c.colId}:${c.sort}`)
    .join(',');

  return `f1|${filterString}|${sortString}`;
}

function parseSortAndFilter(raw: string): any[] {
  const [version, ...parts] = raw.split('|');
  if (version === 'f1') {
    const [filter_raw, sort_raw] = parts;
    const filters = filter_raw
      ? tryCatch(() => JSON.parse(filter_raw), always({}))()
      : {};
    const sort = [];
    for (const [colId, dir] of sort_raw.split(',').map((s) => s.split(':'))) {
      if (colId && dir) {
        sort.push({ colId, sort: dir });
      }
    }

    return [filters, sort];
  }
  return [];
}

export const ServersGrid: Component<{
  servers: Accessor<Server[] | undefined>;
  updates: Observable<Server>;
  onViewStateChange?: (state: string) => void;
  viewState?: string;
  onRowClicked?: (server: string) => void;
}> = (props) => {
  let grid!: GridApi;
  let updates_sub: Subscription | undefined;
  let extant_ids = new Set<string>();

  const onRowClicked = (params: any) => {
    if (params.event.target.innerText === 'Join') {
      return;
    }
    props.onRowClicked?.(params.data.address);
  };

  const onFilterChanged = (params: any) => {
    props.onViewStateChange?.(serializeSortAndFilter(grid));
    updateHeader(grid);
  };
  const onSortChanged = (params: any) => {
    props.onViewStateChange?.(serializeSortAndFilter(grid));
  };

  const onGridReady = () => {
    updates_sub = props.updates
      .pipe(bufferTime(100))
      .subscribe((new_servers) => {
        if (new_servers.length === 0) return;

        const update = [],
          add = [];
        for (const server of new_servers) {
          const id = getRowId(server);
          if (extant_ids.has(id)) {
            update.push(server);
          } else {
            extant_ids.add(id);
            add.push(server);
          }
        }
        grid.applyTransaction({
          update,
          add,
        });
        updateHeader(grid);
      });

    let x = props.viewState;
    if (x) {
      let [filters, sort] = parseSortAndFilter(x);

      if (filters) grid.setFilterModel(filters);
      if (sort?.length) grid.applyColumnState({ state: sort });

      updateHeader(grid);
    }
  };

  onCleanup(() => {
    updates_sub?.unsubscribe();
  });

  const onDataLoaded = (rows: Server[]) => {
    extant_ids = new Set(rows.map(getRowId));
    updateHeader(grid);
  };

  return (
    <AgGrid<Server>
      ref={(e) => {
        grid = e;
      }}
      className={styles.grid}
      data={props.servers() || []}
      getRowId={({ data }) => getRowId(data)}
      columnDefs={kServerColumnDefs}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      onRowClicked={onRowClicked}
      onRowDoubleClicked={({ data: { address } }: GridContext) =>
        window.open(`steam://connect/${address}`, '_self')
      }
      onGridReady={onGridReady}
      onDataLoaded={onDataLoaded}
    />
  );
};
