import { kRegionName, kRegionAbbreviation, Server, Region } from './types';
import { IHeaderParams, IHeaderComp, Grid } from '@ag-grid-community/core';
import styles from './AgGridComponents.module.scss';

export interface IconHeaderParams {
  icon: HTMLElement;
}

export class IconHeader implements IHeaderComp {
  private agParams!: IHeaderParams & IconHeaderParams;
  private eGui!: HTMLElement;
  private sortState!: 'asc' | 'desc' | null;

  init(agParams: IHeaderParams & IconHeaderParams) {
    this.agParams = agParams;
    this.eGui = this.agParams.icon.cloneNode() as HTMLElement;
    this.eGui.addEventListener('click', this.onSortClick.bind(this) as any);
    this.sortState = null;
    this.agParams.column.addEventListener('sortChanged', () => {
      if (this.agParams.column.isSortAscending()) {
        this.sortState = 'asc';
      } else if (this.agParams.column.isSortDescending()) {
        this.sortState = 'desc';
      } else {
        this.sortState = null;
      }
    });
  }

  // reverse of the normal sort order, works better for bools
  onSortClick(event: MouseEvent) {
    let nextState: 'asc' | 'desc' | null = null;
    if (this.sortState === null) {
      nextState = 'desc';
    } else if (this.sortState === 'desc') {
      nextState = 'asc';
    }
    this.agParams.setSort(nextState, event.shiftKey);
  }

  getGui() {
    return this.eGui;
  }

  refresh() {
    return false;
  }
}

function regionFilterButton(region: Region, name: string): string {
  return `<li><label>
     <input type="checkbox" data-region="${region}" checked="true" filter-checkbox="true"/> ${name}
  </label></li>`;
}

export class RegionFilter {
  private eGui!: HTMLElement;
  private checkboxes!: NodeListOf<HTMLInputElement>;
  private filterActive!: boolean;
  private activeRegions!: Set<Region>;
  private filterChangedCallback!: () => void;

  init(params: any) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div class="${styles.region_filter}">
         <header><button>All</button> <button>None</button></header>
         <ul>
             ${[...kRegionName.entries()]
               .map(([k, v]) => regionFilterButton(k, v))
               .join('')}
         </ul>
      </div>`;

    const [all, none] = this.eGui.querySelectorAll('button');
    all.addEventListener('click', this.selectAll.bind(this));
    none.addEventListener('click', this.selectNone.bind(this));

    this.checkboxes = this.eGui.querySelectorAll('input');
    for (const el of this.checkboxes) {
      el.addEventListener('change', this.onChanged.bind(this));
    }

    this.filterActive = false;
    this.activeRegions = new Set(kRegionName.keys());
    this.filterChangedCallback = params.filterChangedCallback;
  }

  selectAll(event: Event) {
    this.activeRegions = new Set(kRegionName.keys());
    this.checkboxes.forEach((c) => (c.checked = true));
    this.filterActive = this.activeRegions.size < kRegionName.size;
    this.filterChangedCallback();
  }

  selectNone(event: Event) {
    this.activeRegions.clear();
    this.checkboxes.forEach((c) => (c.checked = false));
    this.filterActive = this.activeRegions.size < kRegionName.size;
    this.filterChangedCallback();
  }

  onChanged(event: any) {
    let region = parseInt(event.target.dataset.region, 10);
    let checked = event.target.checked;
    if (checked) {
      this.activeRegions.add(region);
    } else {
      this.activeRegions.delete(region);
    }
    this.filterActive = this.activeRegions.size < kRegionName.size;
    this.filterChangedCallback();
  }

  getGui() {
    return this.eGui;
  }

  doesFilterPass(params: any) {
    return this.activeRegions.has(params.data.region);
  }

  isFilterActive() {
    return this.filterActive;
  }

  getModel() {
    if (!this.filterActive) return null;
    return { regions: [...this.activeRegions] };
  }

  setModel(model: any, ...args: any[]) {
    if (!model) {
      this.filterActive = false;
      return;
    }
    let { regions } = model;
    this.activeRegions = new Set(regions);
    this.filterActive = true;
  }
}

export class JoinFilter {
  private eGui!: HTMLElement;
  private fullOnly!: boolean;
  private filterActive!: boolean;
  private filterChangedCallback!: () => void;

  init(params: any) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div class="${styles.join_filter}">
            <button>All</button>
            <button>Can Join</button>
            <button>Full</button>
      </div>`;

    const [all, join, full] = this.eGui.querySelectorAll('button');
    all.addEventListener('click', this.selectAll.bind(this));
    join.addEventListener('click', this.selectJoinable.bind(this));
    full.addEventListener('click', this.selectFull.bind(this));

    this.fullOnly = false;
    this.filterActive = false;
    this.filterChangedCallback = params.filterChangedCallback;
  }

  selectAll(event: Event) {
    this.filterActive = false;
    this.filterChangedCallback();
  }

  selectJoinable(event: Event) {
    this.fullOnly = false;
    this.filterActive = true;
    this.filterChangedCallback();
  }

  selectFull(event: Event) {
    this.fullOnly = true;
    this.filterActive = true;
    this.filterChangedCallback();
  }

  getGui() {
    return this.eGui;
  }

  doesFilterPass(params: any) {
    const is_full = params.data.players >= params.data.max_players;
    return this.fullOnly ? is_full : !is_full;
  }

  isFilterActive() {
    return this.filterActive;
  }

  getModel() {
    if (!this.filterActive) return null;
    return { full: this.fullOnly };
  }

  setModel(model: any) {
    if (!model) {
      this.filterActive = false;
      return;
    }
    this.fullOnly = !!model.full;
    this.filterActive = true;
  }
}

export class PlayersFilter {
  private eGui!: HTMLElement;
  private filterChangedCallback!: () => void;
  private currentPlayers: [number, number] = [0, Infinity];
  private maxPlayers: [number, number] = [0, Infinity];

  init(params: any) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div class="${styles.join_filter}">
            <label for="max_players">Max Players</label>
            <div class="${styles.row}">
              <input type="number" name="max_players_min" min="0" max="100" />
              <input type="number" name="max_players_max" min="0" max="100" />
            </div>
            <label for="current_players">Current Players</label>
            <div class="${styles.row}">
              <input type="number" name="current_players_min" min="0" max="100" />
              <input type="number" name="current_players_max" min="0" max="100" />
            </div>
      </div>`;

    this.eGui
      .querySelectorAll('input')
      .forEach((input) =>
        input.addEventListener('change', this.change.bind(this)),
      );

    this.filterChangedCallback = params.filterChangedCallback;
  }

  change(event: Event) {
    if (!event || !event.target) return;
    const target = event.target as HTMLInputElement;
    let value = +target.value;
    if (target.name === 'current_players_min') {
      this.currentPlayers[0] = value;
    } else if (target.name === 'current_players_max') {
      this.currentPlayers[1] = value;
    } else if (target.name === 'max_players_min') {
      this.maxPlayers[0] = value;
    } else if (target.name === 'max_players_max') {
      this.maxPlayers[1] = value;
    }
    this.filterChangedCallback();
  }

  getGui() {
    return this.eGui;
  }

  doesFilterPass(params: any) {
    let real_players = params.data.players - params.data.bots;
    return (
      this.currentPlayers[0] <= real_players &&
      real_players <= this.currentPlayers[1] &&
      this.maxPlayers[0] <= params.data.max_players &&
      params.data.max_players <= this.maxPlayers[1]
    );
  }

  isFilterActive() {
    return (
      this.currentPlayers[0] > 0 ||
      this.currentPlayers[1] < 200 ||
      this.maxPlayers[0] > 0 ||
      this.maxPlayers[1] < 200
    );
  }

  getModel() {
    const out = {} as any;
    if (this.maxPlayers[0] !== 0 || this.maxPlayers[1] !== Infinity) {
      out.maxPlayers = encodeRange(this.maxPlayers);
    }
    if (this.currentPlayers[0] !== 0 || this.currentPlayers[1] !== Infinity) {
      out.currentPlayers = encodeRange(this.currentPlayers);
    }
    return out;
  }

  setModel(model: any) {
    if (!model) return;
    this.maxPlayers[0] = model?.maxPlayers?.[0] ?? 0;
    this.maxPlayers[1] = model?.maxPlayers?.[1] ?? Infinity;

    this.currentPlayers[0] = model?.currentPlayers?.[0] ?? 0;
    this.currentPlayers[1] = model?.currentPlayers?.[1] ?? Infinity;

    const [maxPlayersMin, maxPlayersMax, currentPlayersMin, currentPlayersMax] =
      this.eGui.querySelectorAll('input');
    maxPlayersMin.value = String(this.maxPlayers[0]);
    maxPlayersMax.value = String(this.maxPlayers[1]);
    currentPlayersMin.value = String(this.currentPlayers[0]);
    currentPlayersMax.value = String(this.currentPlayers[1]);
  }
}

function encodeRange([min, max]: [number, number]) {
  return [min, max === Infinity ? 200 : max];
}
