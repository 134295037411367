import {
  createSignal,
  useContext,
  Show,
  Component,
  For,
  createContext,
  onMount,
  onCleanup,
  createEffect,
  createResource,
  createSelector,
  JSXElement,
  Accessor,
  children,
} from 'solid-js';
import { indexBy, prop } from 'ramda';
import { Store, createStore } from 'solid-js/store';
import type { Server } from './types';
import { Dynamic } from 'solid-js/web';
import { ServersGrid } from './ServersGrid';
import { ServerView } from './ServerView';
import styles from './App.module.scss';
import { createReconnectingWS } from '@solid-primitives/websocket';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StoreContext } from './store';
import { StreamContext } from './stream';
import { getServers } from './api';
import { TabsRouter, TabsDef, TabsRouterDef } from './Tabs';
import { cleanServer } from './utils';

const ServersContext = createContext<Accessor<Server[] | undefined>>();

const Servers: Component<{
  servers: Accessor<Server[] | undefined>;
  updates: Observable<Server>;
}> = (props) => {
  const [viewState, setViewState] = createSignal(
    decodeURIComponent(window.location.hash.substr(1)),
  );
  const [focusServer, setFocusServer] = createSignal<string | null>(null);

  let listener = () =>
    setViewState(decodeURIComponent(window.location.hash.substr(1)));
  onMount(() => {
    window.addEventListener('hashchange', listener);
  });
  onCleanup(() => window.removeEventListener('hashchange', listener));

  return (
    <div class={styles.panel_container}>
      <div class={styles.panel}>
        <ServersGrid
          servers={props.servers}
          updates={props.updates}
          viewState={viewState()}
          onRowClicked={setFocusServer}
          onViewStateChange={(s) =>
            (window.location.hash = `#${encodeURIComponent(s)}`)
          }
        />
      </div>
      <Show when={focusServer() !== null}>
        <div class={styles.panel}>
          <header>
            <span onClick={() => setFocusServer(null)}>✕</span>
          </header>
          <ServerView address={focusServer()!} />
        </div>
      </Show>
    </div>
  );
};

export const Internet: Component<{}> = () => {
  const servers = useContext(ServersContext)!;
  const updates = useContext(StreamContext)!;
  return <Servers servers={servers} updates={updates} />;
};

export const Favorites: Component<{}> = (props) => {
  const servers = useContext(ServersContext)!;
  const updates = useContext(StreamContext)!;
  return (
    <Servers
      servers={() =>
        servers()?.filter((s) => s.address == '23.92.22.188:27015')
      }
      updates={updates.pipe(filter((s) => s.address == '23.92.22.188:27015'))}
    />
  );
};

const kTabs: TabsRouterDef<{}> = [
  ['Internet', '/'],
  ['Favorites', '/favorites'],
  ['Admin', '/admin'],
];

export const App: Component<{ children?: JSXElement }> = (props) => {
  const [region, setRegion] = createSignal('');
  const [servers, { mutate, refetch }] = createResource(region, getServers);
  const [store, updateStore] = createStore({ servers: {} });

  const updates = new Subject<Server>();
  const ws = createReconnectingWS(
    `${import.meta.env.VITE_API_WS_BASE}feed`,
    /*protocols=*/ [],
    {
      delay: 5000,
      retries: 5,
    },
  );

  ws.addEventListener('message', (ev) =>
    updates.next(cleanServer(JSON.parse(ev.data))),
  );
  ws.addEventListener('error', (e: any) => console.error(e));

  createEffect(() => {
    let allServers = servers() || [];
    let allServersMap: { [address: string]: Server } = indexBy<Server>(
      prop('address'),
    )(allServers);
    updateStore('servers', allServersMap);
  });

  return (
    <ServersContext.Provider value={servers}>
      <StoreContext.Provider value={store}>
        <StreamContext.Provider value={updates}>
          <div class={styles.app}>
            <TabsRouter tabs={kTabs} nested_props={{ servers, updates }}>
              {props.children}
            </TabsRouter>
          </div>
        </StreamContext.Provider>
      </StoreContext.Provider>
    </ServersContext.Provider>
  );
};
