export type Server = {
  address: string;
  password: boolean;
  vac: boolean;
  name: string;
  game: string;
  players: number;
  max_players: number;
  bots: number;
  map: string;
  keywords: string[];
  region: number;
};

export type Player = {
  index: number;
  name: string;
  score: number;
  seconds: number;
};

// [rule_name, value]
export type ServerRule = [string, string];

export type Region = number;

export const kRegionName = new Map();
kRegionName.set(0, 'US (East)');
kRegionName.set(1, 'US (West)');
kRegionName.set(2, 'South America');
kRegionName.set(3, 'Europe');
kRegionName.set(4, 'Asia');
kRegionName.set(5, 'Australia');
kRegionName.set(6, 'Middle East');
kRegionName.set(7, 'Africa');
kRegionName.set(255, 'Unspecified');

export const kRegionAbbreviation = new Map();
kRegionAbbreviation.set(0, 'US-E');
kRegionAbbreviation.set(1, 'US-W');
kRegionAbbreviation.set(2, 'SA');
kRegionAbbreviation.set(3, 'EU');
kRegionAbbreviation.set(4, 'ASIA');
kRegionAbbreviation.set(5, 'AU');
kRegionAbbreviation.set(6, 'ME');
kRegionAbbreviation.set(7, 'AF');
kRegionAbbreviation.set(255, '');

export type StatusPlayer = {
  id: number;
  name: string;
  id64: string;
  connected: string;
  ping: number;
  loss: number;
  state: string;
};

export type Status = {
  hostname?: string;
  address?: string;
  players: StatusPlayer[];
};
