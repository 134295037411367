import {
  ValidComponent,
  createSignal,
  Component,
  For,
  JSXElement,
  children,
} from 'solid-js';
import { Dynamic } from 'solid-js/web';
import styles from './Tabs.module.scss';
import { RouteDefinition, Router, A } from '@solidjs/router';

export type TabsDef<T> = [string, Component<T>][];
export type TabsRouterDef<T> = [string, string][];

type TabProps<T, D = TabsDef<T>> = {
  tabs: D;
  children?: any;
  nested_props: T;
};

export function Tabs<T>(props: TabProps<T>): JSXElement {
  const [selected_index, set_selected_index] = createSignal(0);

  return (
    <>
      <div class={styles.tabs}>
        <For each={props.tabs}>
          {([name], index) => (
            <button
              classList={{
                [styles.tab_button]: true,
                [styles.active]: selected_index() === index(),
              }}
              onClick={() => set_selected_index(index)}
            >
              {name}
            </button>
          )}
        </For>
      </div>
      <Dynamic
        component={props.tabs[selected_index()][1]}
        {...props.nested_props}
      />
    </>
  );
}

export function TabsRouter<T>(
  props: TabProps<T, TabsRouterDef<T>>,
): JSXElement {
  const [selected_index, set_selected_index] = createSignal(0);
  const c = children(() => props.children);

  return (
    <>
      <div class={styles.tabs}>
        <For each={props.tabs}>
          {([name, path], index) => (
            <A
              inactiveClass={styles.tab_button}
              activeClass={`${styles.tab_button} ${styles.active}`}
              href={path}
              onClick={() => set_selected_index(index)}
              end={path === '/'}
            >
              {name}
            </A>
          )}
        </For>
      </div>
      {c()}
    </>
  );
}
